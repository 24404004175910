import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible'
import Vant from 'vant'
import 'vant/lib/index.css'
import axios from 'axios'
import './assets/styles/global.css'
import { Dialog } from 'vant'
import Carousel3d from 'vue-carousel-3d'
import Meta from 'vue-meta';
import 'swiper/dist/css/swiper.min.css';
import 'swiper/dist/js/swiper.min';
// import './registerServiceWorker.js'
Vue.use(Vant)
Vue.use(Dialog)
Vue.use(Carousel3d)
Vue.use(Meta)
Vue.config.productionTip = false

new Vue({
  axios,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
