import http from '../utils/http'
// get请求
const userApi = {
  //获取用户信息
  getUserInfo() {
    return http.get('/api/user/info')
  },
  login(params) {
    return http.post('/api/login/account', params)
  },
  //注冊
  register(params) {
    return http.post('/api/login/register', params)
  },
  //退出登錄
  logOut() {
    return http.post('/api/login/logout')
  },
  //獲取學習情況
  getUserLearning(params) {
    return http.get('/api/user/stage', params)
  },
  //獲取所有年级
  getClassListAll() {
    return http.get('/api/user/classList')
  },
  //修改年級
  changeClass(params) {
    return http.post('/api/user/setAloneStage', params)
  },
  //設置學習情況
  setUserLearning(params) {
    return http.post('/api/user/setstage', params)
  },
  //头像上传
  attachUpload(data) {
    // 创建一个 FormData 对象来传递文件
    const formData = new FormData()
    formData.append('file', data.file) // 假设你的文件在 data 对象的 file 属性中
    let headers = {
      'Content-Type': 'multipart/form-data',
    }
    return http.get('/api/upload/image', formData, headers)
  },
  //修改個人信息
  changeUser(params) {
    return http.post('/api/user/setInfo', params)
  },
  //發送郵箱驗證
  sendEmail(params) {
    return http.post('api/login/sendEmail', params)
  },
  //校驗驗證嗎
  verify(params) {
    return http.post('api/login/verify', params)
  },
  //修改密碼
  forgePwd(params) {
    return http.post('api/login/forgePwd', params)
  },
  //獲取個人獎項列表
  getDiplomaList() {
    return http.get('/api/diploma/list')
  },
  //添加證書
  addDiploma(params) {
    return http.post('api/diploma/add', params)
  },
  //刪除證書
  delDiploma(params) {
    return http.get('/api/diploma/delete', params)
  },
  //我的订单
  getMyOrder() {
    return http.get('/api/user/order')
  },
  //我的課程
  getMyCourse() {
    return http.get('/api/user/course')
  },
}
export default userApi
